const lozad = require("lozad");
const SmoothScroll = require("smooth-scroll/dist/smooth-scroll");

lozad(".lazy", {
  loaded: function(el) {
    el.classList.add("loaded");
  }
}).observe();

const scroll = new SmoothScroll('a[href*="#"]', {
  easing: "easeInOutCubic",
  speedAsDuration: true
});

const HeroRenderer = import("./hero").then(HeroRenderer => {
  const hero = new HeroRenderer("hero", "hero-anchor");
});

// Blog theme
// https://github.com/EYHN/hexo-theme-one
// https://erikbern.com/
// http://blog.zhangruipeng.me/hexo-theme-icarus/
